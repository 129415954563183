import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/work/vpdesign",
  "title": "VPDesign",
  "description": "Creating a website that reflects a freelance persona that's relatable to businesses looking for work",
  "workTags": ["UX/UI Design", "Webflow Development"],
  "tags": ["Website Design", "Webflow"],
  "coverPhoto": {
    "cloudinaryAssetData": true,
    "cloudName": "dmocgebcq",
    "publicId": "portfolio/preview-images/vpdesigns-mockup-long_2x_fsag5x.png",
    "originalHeight": 1248,
    "originalWidth": 2128,
    "defaultBase64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMMXG/8HwAEwAI0Bj1bnwAAAABJRU5ErkJggg==",
    "defaultTracedSVG": "data:image/svg+xml,%3Csvg%20height%3D%229999%22%20viewBox%3D%220%200%209999%209999%22%20width%3D%229999%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m0%200h9999v9999h-9999z%22%20fill%3D%22%23f9fafb%22%2F%3E%3C%2Fsvg%3E"
  },
  "work_jumbo_title": "Freelancing website created for customers",
  "laptop_image": "https://res.cloudinary.com/dmocgebcq/image/upload/v1639095201/portfolio/laptop-images/vpdesign-laptop-mock_lco95l.png",
  "role": "Designer/Developer",
  "deliverable": "Webflow Website",
  "url": "https://toris-test-project-2.webflow.io/",
  "github": null,
  "client": "Self",
  "draft": false,
  "type": "work",
  "order": 4,
  "date": "2018-01-03T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`The Challenge`}</h3>
    <p>{`I have a site that’s more technical and showcases the skills I’m developing and working on. For freelancing I wanted to create a specific site. This site should be more focused on customer’s and getting a sale on making a site. This means answering their questions, showing I can be trusted, and building a connection. This also seemed like a good time to test out using Webflow to create a website. It seems like an interesting tool to build with and making a website with it seems like great practice.`}</p>
    <h3>{`Work In Progress`}</h3>
    <p>{`Still getting the site ready to go and improving everytime I make a new page. I think this will be a great service to know and is a great way to get some good looking and highly functional websites out on the internet to hopefully replace some of those awful looking sites or Google Doc driven sites I've seen pop up lately.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      